<template>
  <div id="project-finder-app">
    <div><Header /></div>
    <b-row class="body-container">
      <b-col cols="12" md="4">
        <div class="sidebar-container">
          <div class="col-md-12">
            <table class="table b-table table-hover table-bordered">
              <thead>
                <tr>
                  <th width="80px">ID</th>
                  <th>名前</th>
                  <th width="100px">状態</th>
                  <th width="150px">最終更新</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filteredItems" :key="item.id">
                  <td>{{ item.properties.id }}</td>
                  <td>{{ item.properties.fullName }}</td>
                  <td
                    :style="{ background: getRowColor(item.properties.status) }"
                  >
                    {{ item.properties.status }}
                  </td>
                  <td>{{ item.properties.lastUpdated }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="driverHistory != null" class="driver-tracking-detail-first-date">{{ driverHistory.data[0].date }}</div>
          <div
            class="row driver-tracking-scroll-view"
            v-bind:class="{ vScroll: hasScroll }"
            ref="tbod"
            v-if="driverHistory != null"
            @scroll="handleScroll($event)"
          >
            <div
              class="col-md-12"
              v-for="item in driverHistory.data"
              :key="item.date"
            >
              <div v-if="item.date != driverHistory.data[0].date"
                class="driver-tracking-detail-date">{{ item.date }}
              </div>
              <div>
                <table class="table b-table table-hover table-bordered">
                  <tbody>
                    <tr
                      v-for="itemHistory in item.body"
                      :key="itemHistory.lastUpdate"
                    >
                      <td width="100px">{{ itemHistory.lastUpdate }}</td>
                      <td :class="viewSpeed(itemHistory.speed)" width="150px">
                        {{ itemHistory.speed }}
                      </td>
                      <td>{{ itemHistory.address }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="8"><DriverTrackingMap /></b-col>
    </b-row>
  </div>
</template>

<script>
import Header from "@/views/Location/Header.vue";
import DriverTrackingMap from "@/views/Location/DriverTrackingMap";
import { mapState } from "vuex";

export default {
  name: "DriverTrackingDetail",
  components: {
    Header,
    DriverTrackingMap,
  },

  computed: {
    ...mapState(["currentFeatures"]),
    filteredItems() {
      return this.currentFeatures.features.filter((item) => {
        return item.properties.id == this.$route.query.id;
      });
    },
  },
  data() {
    return {
      hasScroll: false,
      driverHistory: null,
    };
  },
  mounted() {
    this.driverHistory = this.currentFeatures.histories.filter((history) => {
      return history.id == this.$route.query.id;
    })[0];
    this.$nextTick(() => {
      this.hasScroll = this.$refs.tbod.scrollHeight > this.$refs.tbod.clientHeight;      
    });
    this.hiddenScroll();
  },
  methods: {
    hiddenScroll(){
      var style = document.createElement("style");
      style.innerHTML = '.driver-tracking-scroll-view::-webkit-scrollbar {width: 15px; display: none;}';
      document.head.appendChild(style);
    },

    handleScroll(e){
      let isUserScrolling = (e.target.scrollTop > 0);
      let hiddenStyle = '.driver-tracking-scroll-view::-webkit-scrollbar {width: 15px; display: none;}';
      let displayStyle = '.driver-tracking-scroll-view::-webkit-scrollbar {width: 15px; display: inline;}';
      let styleList = document.getElementsByTagName("style")
      if(!isUserScrolling)
      {
        document.head.removeChild(styleList[styleList.length - 1]);
        var hidden = document.createElement("style");
        hidden.innerHTML = hiddenStyle;
        document.head.appendChild(hidden);        
      }else{
        document.head.removeChild(styleList[styleList.length - 1]);
        var display = document.createElement("style");
        display.innerHTML = displayStyle;
        document.head.appendChild(display);        
      }
    },

    viewSpeed: function (val) {
      const speed = val.replace(" km/h", "");
      if (parseFloat(speed) > 60) {
        return "color-red";
      }
      return "";
    },

    getRowColor(status) {
      switch (status) {
        case "移動中":
          this.color = "rgb(206,214,255)";
          break;
        case "退勤":
          this.color = "rgb(231,231,231)";
          break;
        case "乗務前":
          this.color = "rgb(255,255,183)";
          break;
        default:
          this.color = "rgb(255,206,206)";
      }
      return this.color;
    },
  },
};
</script>

<style scoped>
@media (max-width: 1512px) and (min-width: 1200px) {
  .col-md-4 {
    max-width: 100%;
    flex: 0 0 45.333333%;
  }
  .col-md-8 {
    max-width: 100%;
    flex: 0 0 54.666667%;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .col-md-4 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .col-md-8 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

#project-finder-app{
  line-height: 1.2;
}

.body-container {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 7.1rem;
}

.table-bordered {
  box-shadow: 0px 5px #d8d8d8;
  color: #000000;
}

.table-bordered thead th {
  background-color: #e5e5e5;
  border-bottom-width: 1px;
  border: 1px solid #000000;
  font-weight: normal;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #080808;
  font-size: 1.5rem;
}
.driver-tracking-detail-date {
  padding: 5px 0px 20px 20px;
  font-size: 1.5rem;
}
.driver-tracking-detail-first-date {
  padding: 5px 0px 20px 35px;
  font-size: 1.5rem;
}
.driver-tracking-scroll-view {
  max-height: 67vh;
  overflow-y: auto;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 10px;
}
.vScroll {
  margin-right: 0px;
}
/* width */

/* Track */
.driver-tracking-scroll-view::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: 1px dotted rgb(12, 12, 12);
  border-radius: 5px;
}

.driver-tracking-scroll-view::-webkit-scrollbar-track-piece:start {
  top: 10px;
}

/* Handle */
.driver-tracking-scroll-view::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #C4C4C4;
  border: 1px solid #000000;
}

/* Handle on hover */
.driver-tracking-scroll-view::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 105);
}
.color-red {
  color: red;
}
</style>
